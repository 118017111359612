import React from "react";

const AddBtn = ({ start, end, increase }) => (
    <span
        onTouchStart={start}
        onMouseUp={end}
        onTouchEnd={end}
        onTouchCancel={end}
        onMouseDown={start}
        onClick={increase}
        className="material-icons shdow-1 rounded-full p-1 text-5xl cyan-text">
        add
    </span>
);
export default AddBtn;
