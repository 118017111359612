import React from "react";
import useLocalStorage from "@rehooks/local-storage";
import { BrowserRouter as Router } from "react-router-dom";
import AuthRoutes from "./Routes/AuthRoutes";
import ResiverRoutes from "./Routes/ResiverRoutes";
import UnAuthRoutes from "./Routes/UnAuthRoutes";

function App() {
    const [theme, setTheme] = useLocalStorage("theme");
    const [user] = useLocalStorage("user");

    const _theme = theme || "dark"; // (window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light");
    const changeTheme = () => {
        setTheme(_theme !== "dark" ? "dark" : "light");
        document.documentElement.style.setProperty("--main-bg-color", _theme !== "dark" ? "#22212e" : "#f5f5f5");
    };
    React.useEffect(() => {
        document.documentElement.style.setProperty("--main-bg-color", _theme === "dark" ? "#22212e" : "#f5f5f5");
    }, []);

    return (
        <Router>
            <div className={`mx-auto max-w-5xl px-2 ${_theme}`}>
                <p style={{ zIndex: 10000, left: 0, top: -10 }} className="font-light text-2xl fixed bg-white" onClick={changeTheme}>
                    {_theme === "dark" ? "🌙" : "🌞"}
                </p>
                {user ? user.phoneNumber ? <ResiverRoutes /> : <AuthRoutes /> : <UnAuthRoutes />}
            </div>
        </Router>
    );
}

export default App;
