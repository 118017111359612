import React from "react";
import { Routes, Route } from "react-router-dom";
import ReseverBillView from "../Bills/ReseverBillView";
import HomeView from "../Home/HomeView";
import BottomBarResver from "../Navigations/BottomBarResver";
// import HomeView from "../Home/HomeView";
// import ItemsView from "../Items/ItemsView";
// import CategoriesView from "../Categories/CategoriesView";
// import FavortiesItemsView from "../Items/FavortiesItemsView";

const ResiverRoutes = () => {
    return (
        <>
            <Routes>
                <Route path="*" element={<ReseverBillView />} />
                <Route path="/" element={<HomeView />} />
                {/* <Route path="/" element={<HomeView />} />
            <Route path="/categories" element={<CategoriesView />} />
            <Route path="/categories/favorites" element={<CategoriesView />} />
            <Route path="/Items" element={<ItemsView />} />
            <Route path="/Items/favorites" element={<FavortiesItemsView />} />
            <Route path="/Items/:categoryId" element={<ItemsView />} />
            <Route path="/Items/:categoryId/favorites" element={<FavortiesItemsView />} /> */}
            </Routes>
            <BottomBarResver />
        </>
    );
};

export default ResiverRoutes;
