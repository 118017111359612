import useLocalStorage from "@rehooks/local-storage";
import React from "react";
// import { Link } from "react-router-dom";
import AuthView from "../Auth/AuthView";
import Button from "../Components/Button";
import Scroller from "../Containers/Scroller";

const DashView = () => {
    const [user, setUser] = useLocalStorage("user");
    const [popup, setPopup] = React.useState(false);
    const addMyNumber = (phoneNumber) => setUser({ ...user, phoneNumber });

    const addResever = (phoneNumber) => {
        setPopup(false);
        alert(`سيتم ارسال طلباتك الى - ${phoneNumber}`);
        setTimeout(() => {
            setUser({ resever: phoneNumber });
        }, 1000);
    };

    return (
        <Scroller className="col justify-around h-full">
            <div className="col-center justify-center my-10 flex-grow carbonic-card rounded-3 shdow-1">
                <p className="font-light pointer carbonic text-center text-4xl"> جيبـــلـنة ويــــاك </p>
                <Button onClick={() => setPopup("resever")} color="red" title="إرسال الطلبات" />
            </div>
            <div className="col-center justify-center my-10 flex-grow carbonic-card rounded-3 shadow-1">
                <p className="font-light pointer carbonic text-center text-4xl"> تدللون </p>
                <Button onClick={() => setPopup("user")} color="cyan" title="تلقي الطلبات" />
            </div>

            {popup &&
                (popup === "resever" ? (
                    <AuthView onComplate={addResever} titel="هاتف المستلم" cancle={() => setPopup(false)} />
                ) : (
                    popup === "user" && <AuthView onComplate={addMyNumber} cancle={() => setPopup(false)} />
                ))}
        </Scroller>
    );
};

export default DashView;
