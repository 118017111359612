import React, { useState, useEffect } from "react";
import InfoLabel from "../Components/InfoLabel";
import Scroller from "../Containers/Scroller";
import { Bills } from "../Api/Bills";
import ItemCard from "./Components/ItemCard";

const ReseverBillView = () => {
    const [bill, setBill] = useState([]);
    const [loading, setloading] = useState(true);

    const fetchItems = async () => {
        let _bill = await Bills.GetBill();
        _bill && _bill.items && setBill(_bill.items);
        setloading(false);
    };

    useEffect(() => {
        fetchItems();
    }, []);

    return (
        <>
            <Scroller className="larg-grid" loading={loading}>
                {bill.length > 0 ? bill.map((item, i) => <ItemCard key={i} item={item} />) : <InfoLabel title="لاتوجد طلبات" />}
            </Scroller>
        </>
    );
};

export default ReseverBillView;
