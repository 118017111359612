import React from "react";
import AddBtn from "./AddBtn";
import RemoveBtn from "./RemoveBtn";
import Formatter from "../Utils/Formatter";
let longPress = -1,
    timer = 150;
const CounterForm = ({ item, onClick, onTextClick, onDiscountClick, quantityDiscount }) => {
    const increase = () => onClick();
    const decrease = () => {
        if (item.quantity > 0) onClick(-1);
    };

    const start = (_increase) => {
        let randomToKepp = Math.random();
        longPress = randomToKepp;
        setTimeout(() => {
            if (longPress === randomToKepp) startActing(_increase ? increase : decrease, randomToKepp);
        }, 400);
    };

    const startActing = (action, randomToKepp) => {
        setTimeout(() => {
            if (longPress === randomToKepp) {
                action();
                if (item.quantity > 0) startActing(action, randomToKepp);
                if (timer > 30) timer -= 3;
            }
        }, timer);
    };

    const end = () => {
        longPress = -1;
        timer = 150;
    };
    return (
        <p className="row-center" style={{ height: 30, marginBottom: 5 }}>
            <AddBtn start={start} end={end} increase={increase} />
            {item.quantity > 0 && (
                <>
                    <span
                        style={{ width: 45 }}
                        className="shdow-1 rounded-full py-5 text-2xl red-card white-text text-center mx-6 fade-qantity-btn"
                        onClick={onTextClick}>
                        {item.quantity}
                    </span>
                    <RemoveBtn end={end} start={start} decrease={decrease} />
                    <span className="white-text orange-card text-xl px-4 py-1  rounded-2 " style={{ marginRight: "auto" }}>
                        {Formatter(item.price * item.quantity, 1)}
                    </span>
                </>
            )}
        </p>
    );
};

export default CounterForm;
