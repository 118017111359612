import React, { useState, useEffect } from "react";
import ItemCard from "../Items/Components/ItemCard";
import Formatter from "../Utils/Formatter";
import DetailsLabel from "../Items/Components/DetailsLabel";
import { GitBills } from "../Api/Items";
import { GetObject } from "../Utils/GetLoaclData";
import InfoLabel from "../Components/InfoLabel";
import Button from "../Components/Button";
import AuthView from "../Auth/AuthView";
import useLocalStorage from "@rehooks/local-storage";
import { Bills } from "../Api/Bills";
import Scroller from "../Containers/Scroller";

let selected = { quantity: 0, totalAmount: 0 };

let totalAmount = 0;
let localItems = [];
let localCategories = {};

const BillsView = () => {
    const [updateUi, setUpdateUi] = useState(false);
    const [items, setItems] = useState([]);
    const [user, setUser] = useLocalStorage("user");
    const [popup, setPopup] = useState(false);
    const [loading, setloading] = useState(true);

    const fetchItems = async () => {
        localItems = await GitBills();
        localCategories = GetObject("localCategories");
        totalAmount = Formatter(
            localItems.reduce((acc, cur) => acc + cur.price * cur.quantity, 0),
            1
        );
        setItems(localItems);
        setloading(false);
    };

    useEffect(() => {
        fetchItems();
    }, []);

    const getFiletered = (categoryId) =>
        categoryId ? localItems.filter((o) => o.quantity > 0 && o.categoryId === categoryId) : localItems.filter((o) => o.quantity > 0);

    const setSelecteds = (categoryId) => {
        let filtered = getFiletered(categoryId);
        selected = {
            categoryId,
            quantity: filtered.length,
            totalAmount: Formatter(
                filtered.reduce((acc, cur) => acc + cur.price * cur.quantity, 0),
                1
            ),
        };
    };

    const updateItemInfos = (item) => {
        localItems = localItems.filter((o) => o.id !== item.id);
        if (item.quantity > 0) {
            localItems.push(item);
        } else {
            if (item.favorite) localItems.push(item);
            setItems(items.filter((o) => o.id !== item.id));
        }
        localStorage.setItem("localItems", JSON.stringify(localItems));
        totalAmount = Formatter(
            localItems.reduce((acc, cur) => acc + cur.price * cur.quantity, 0),
            1
        );
        setSelecteds(item.categoryId);

        localCategories[item.categoryId] = selected;
        localStorage.setItem("localCategories", JSON.stringify(localCategories));

        setUpdateUi(!updateUi);
    };
    const onSubmit = () => {
        if (user && user.resever) {
            let billToSend = {
                id: user.resever,
                items: localItems,
            };
            Bills.SendBill(billToSend);
            alert(`تم الارسال الى  ${user.resever}`);
        } else {
            setPopup("resever");
        }
    };
    const addResever = (phoneNumber) => {
        setUser({ ...user, resever: phoneNumber });
        setPopup(false);
        alert(`تم الارسال الى  ${phoneNumber}`);
    };

    return items.length > 0 ? (
        <>
            <Scroller className="larg-grid" loading={loading}>
                {items.map((item, i) => (
                    <ItemCard key={i} item={item} update={updateItemInfos} />
                ))}
            </Scroller>

            <Button onClick={onSubmit} color="cyan" title="إرسال" extraClass="absolute bottom-2" />
            <DetailsLabel label={{ totalAmount, quantity: items.length }} bottom={40} />
            {popup &&
                (popup === "resever" ? (
                    <AuthView onComplate={addResever} titel="هاتف المستلم" cancle={() => setPopup(false)} />
                ) : (
                    popup === "user" && <AuthView onComplate={addResever} cancle={() => setPopup(false)} />
                ))}
        </>
    ) : (
        <InfoLabel title="لاتوجد طلبات" />
    );
};

export default BillsView;
