import React from "react";
import Language from "../Utils/Languages";

const TextInput = ({ value, setValue, placeholder, required, autoFocus, type = "text" }) => (
    <div className="text-input font-bold row m-2">
        <input
            type={type}
            value={value}
            autoFocus={autoFocus}
            name={placeholder}
            required={required}
            placeholder={Language[placeholder]}
            onChange={({ target }) => setValue(target.value)}
            className="font-bold flex-grow outline-none carbonic border-none"
        />
    </div>
);

export default TextInput;
// <TextInput value={firstName} setValue={setFirstName}  placeholder={Language.Name} />
