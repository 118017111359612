import React from "react";
const ButtonColors = {
    red: "red-card white-text",
    redText: "white-card red-text",
    green: "green-card white-text",
    orange: "orange-card white-text",
    cyan: "cyan-card white-text",
    undefined: "carbonic carbonic-card",
};
const Button = ({ title, onClick, color, extraClass }) => {
    const className = extraClass + " font-bold pointer shdow-1 px-6 py-4 rounded-2 text-center max-w-xs mx-auto my-6 " + ButtonColors[color];
    return (
        <p onClick={onClick} className={className}>
            {title}
        </p>
    );
};

export default Button;
