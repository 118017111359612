import React from "react";
import Form from "../Containers/Form";
import TextInput from "../Components/TextInput";
import PopupContainer from "../Containers/PopupContainer";

const AuthView = ({ onComplate, cancle, titel = "هاتفك لتلقي الطلبات" }) => {
    const [phoneNumber, setPhoneNumber] = React.useState("");
    const [error, setError] = React.useState("تاكيد");

    const onSubmit = () => {
        if (phoneNumber.length === 11) {
            onComplate(phoneNumber);
        } else {
            setError("رقم الهاتف غير صحيح");
            setTimeout(() => {
                setError("تاكيد");
            }, 1000);
        }
    };

    return (
        <PopupContainer close={cancle}>
            <Form cancle={cancle} submitText={error} onSubmit={onSubmit}>
                <p className="font-bold carbonic text-center">
                    <span>الرجاء ادخال رقم</span>
                    <span> {titel} </span>
                </p>
                <TextInput type="number" required setValue={setPhoneNumber} value={phoneNumber} autoFocus />
                {/* <Button onClick={onSubmit} title="تاكيد" color="red" /> */}
                {/* <p className="font-bold red-text text-center">{error}</p> */}
            </Form>
        </PopupContainer>
    );
};

export default AuthView;
