import React from "react";
import { Routes, Route } from "react-router-dom";
import DashView from "../Home/DashView";

const UnAuthRoutes = () => {
    return (
        <div>
            <Routes>
                <Route path="*" element={<DashView />} />
            </Routes>
        </div>
    );
};

export default UnAuthRoutes;
