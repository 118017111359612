import "./input.css";
import "./CSS/index.css";
import "./CSS/items.css";
import "./CSS/categories.css";
import "./CSS/containers.css";
import "./CSS/animations.css";
import "./CSS/global.css";
import "./CSS/theme.css";
import "./CSS/loader.css";
import App from "./App";
import React from "react";
import ReactDOM from "react-dom/client";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
