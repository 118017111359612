import React from "react";
import CounterForm from "../../Components/CounterForm";
import { DefaultImage } from "../../Utils/Languages";

const ItemCard = ({ item, update }) => {
    const [, setQuantity] = React.useState(item.quantity);

    function changeQuantity(value = 1) {
        !item.quantity && (item.quantity = 0);
        item.quantity += value;
        setQuantity(item.quantity);
        update(item);
    }
    function handleFavorite() {
        item.favorite = !item.favorite;
        update(item);
    }
    return (
        <div className={`item-card items-start relative carbonic-card ${item.quantity > 0 && "chosen"}`}>
            <div className="flex-grow row">
                <img src={item.image || DefaultImage} alt={item.name} />
                <p className="carbonic px-4 text-2xl">
                    <span>{item.name}</span>
                    <br />
                    <br />
                    <span> {item.currencyPrice} </span>
                </p>
                <span
                    onClick={handleFavorite}
                    className={`material-icons text-4xl transition-2 ${item.favorite ? "green-text" : "carbonic-2"}`}
                    style={{ marginRight: "auto" }}
                    // style={{ left: 15 }}
                >
                    favorite
                </span>
            </div>
            <CounterForm item={item} onClick={changeQuantity} price={item.price} onTextClick={() => update(item, "popup")} />
        </div>
    );
};

export default ItemCard;
