import React from "react";
import AddBtn from "./AddBtn";
import RemoveBtn from "./RemoveBtn";
let longPress = -1,
    timer = 150;
const CounterFormThemeTow = ({ item, onClick, onTextClick, onDiscountClick, quantityDiscount }) => {
    const increase = () => onClick();
    const decrease = () => {
        if (item.quantity > 0) onClick(-1);
    };
    const start = (_increase) => {
        longPress = true;
        setTimeout(() => {
            startActing(_increase ? increase : decrease);
        }, 400);
    };
    const startActing = (action) => {
        if (longPress)
            setTimeout(() => {
                if (longPress) {
                    action();
                    startActing(action);
                    if (timer > 50) timer -= 5;
                }
            }, timer);
    };

    const end = () => {
        longPress = false;
        timer = 150;
    };
    return (
        <p className="row-center flex-grow justify-between" style={{ height: 30, marginBottom: 5 }}>
            <AddBtn start={start} end={end} increase={increase} />
            {item.quantity > 0 ? (
                <>
                    <span
                        style={{ width: 40 }}
                        className="shdow-1 rounded-full py-4 text-xl red-card white-text text-center fade-qantity-btn"
                        onClick={onTextClick}>
                        {item.quantity}
                    </span>
                    <RemoveBtn end={end} start={start} decrease={decrease} />
                </>
            ) : (
                <span
                    onClick={onTextClick}
                    className="text-center carbonic-card-4 carbonic text-xl shdow-1 flex-grow py-3 rounded-2 "
                    style={{ marginRight: 10 }}>
                    {item.currencyPrice}
                </span>
            )}
        </p>
    );
};

export default CounterFormThemeTow;
