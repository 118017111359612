import React from "react";

const Persions = [
    {
        name: "شيماء",
        age: "21",
        color: "ابيض ",
    },
    {
        name: "فضل",
        age: "5",
        color: "ابيض ",
    },
    {
        name: "همام",
        age: "1",
        color: "ابيض ",
    },
];

const Shimaa = () => {
    return (
        <div className="">
            {Persions.map((pserion, index) => (
                <PersionCard key={index} pserion={pserion} />
            ))}
        </div>
    );
};

const PersionCard = ({ pserion }) => (
    <div className="font-bold carbonic carbonic-card  p-5 m-5 shdow-1 rounded-3 ">
        <p className="red-text text-3xl"> {pserion.name}</p>
        <p>العمر :{pserion.age}</p>
        <p>اللون : {pserion.color}</p>
    </div>
);

export default Shimaa;
