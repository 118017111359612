import React from "react";
import { useLocation } from "react-router-dom";
import Tab from "./Tab";

const Routes = [
    { title: "الرئيسية", path: "/" },
    { title: "المواد", path: "/categories" },
    { title: "المفضلة", path: "/categories/favorites" },
    { title: "الطلبات", path: "/bills" },
];

const BottomBar = () => {
    const [selected, setSelected] = React.useState(0);
    const location = useLocation().pathname;
    React.useEffect(() => {
        setSelected(Routes.findIndex((o) => o.path === location));
    }, [location]);
    return (
        <div className="fixed row-center shdow-1 justify-around backdrop-blur-md blur-card z-7" style={{ bottom: 0, left: 0, right: 0 }}>
            {Routes.map((route, i) => (
                <Tab active={selected === i} key={i} route={route} />
            ))}
        </div>
    );
};

export default BottomBar;
