import React from "react";
import Formatter from "../../Utils/Formatter";
import { DefaultImage } from "../../Utils/Languages";
import CounterFormThemeTow from "../../Components/CounterFormThemeTow";

const ItemCardThemeTwo = ({ item, update }) => {
    function changeQuantity(value = 1) {
        !item.quantity && (item.quantity = 0);
        item.quantity += value;
        update(item);
    }
    function handleFavorite() {
        item.favorite = !item.favorite;
        update(item);
    }
    return (
        <div className={`item-card-2 col relative carbonic-card ${item.quantity > 0 && "chosen-t2"}`}>
            <p style={{ marginTop: -5 }} className="font-bold carbonic px-2">
                <span
                    onClick={handleFavorite}
                    className={`material-icons absolute text-4xl transition-2 ${item.favorite ? "orange-text" : "carbonic-2"}`}
                    style={{ height: 0, left: 15 }}>
                    favorite
                </span>
                <span>{item.name} </span>
            </p>
            <p
                style={{ marginBottom: -20, marginTop: -8, width: "100%", marginRight: -12, paddingInline: 12, height: 20, borderRadius: 3 }}
                className="white-text cyan-card  amount-label text-center">
                {item.quantity > 0 ? Formatter(item.price * item.quantity, 1) : 0}
            </p>
            <img src={item.image || DefaultImage} alt={item.name} />
            <CounterFormThemeTow item={item} onClick={changeQuantity} onTextClick={() => update(item, "popup")} />
        </div>
    );
};

export default ItemCardThemeTwo;
